import { stringify } from 'query-string'
import { GENERIC_ACTION } from '../../container/SearchResults/logic'

const ADMIN_APPROVAL = 'ADMIN_APPROVAL'
const ADMIN_APPROVAL_SUCCESS = 'ADMIN_APPROVAL_SUCCESS'
const ADMIN_APPROVAL_RESET = 'ADMIN_APPROVAL_RESET'
const ADMIN_APPROVAL_FAILURE = 'ADMIN_APPROVAL_FAILURE'

const ADMIN_APPROVAL_EDIT = 'ADMIN_APPROVAL_EDIT'
const ADMIN_APPROVAL_EDIT_SUCCESS = 'ADMIN_APPROVAL_EDIT_SUCCESS'
const ADMIN_APPROVAL_EDIT_RESET = 'ADMIN_APPROVAL_EDIT_RESET'
const ADMIN_APPROVAL_EDIT_FAILURE = 'ADMIN_APPROVAL_EDIT_FAILURE'

const USER_CORRECTION_REQUEST = 'USER_CORRECTION_REQUEST'
const USER_CORRECTION_REQUEST_SUCCESS = 'USER_CORRECTION_REQUEST_SUCCESS'
const USER_CORRECTION_REQUEST_RESET = 'USER_CORRECTION_REQUEST_RESET'
const USER_CORRECTION_REQUEST_FAILURE = 'USER_CORRECTION_REQUEST_FAILURE'

const USER_CORRECTION_SITE_REQUEST = 'USER_CORRECTION_SITE_REQUEST'
const USER_CORRECTION_SITE_REQUEST_SUCCESS = 'USER_CORRECTION_SITE_REQUEST_SUCCESS'
const USER_CORRECTION_SITE_REQUEST_RESET = 'USER_CORRECTION_SITE_REQUEST_RESET'
const USER_CORRECTION_SITE_REQUEST_FAILURE = 'USER_CORRECTION_SITE_REQUEST_FAILURE'

const INITIAL_STATE = {
  data: {},
  loading: false,
  error: false,
  flag: false,
}

export const userCorrectionSiteRequestAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload: payload.body,
    meta: {
      actionType: USER_CORRECTION_SITE_REQUEST,
      url: `${process.env.apiUrl}v0/search/editSiteRequest?${stringify(payload.params)}`,
      method: 'PUT',
      auth: true,
      log: false, // for console
      ...meta,
    },
    showNotification: true, // show notification on success
    message: 'Request has been submitted for approval',
    hideNotification: false, // hide notification on error
    customErrMessage: 'Warning : Site already exists with this name',
  })
}

export const userCorrectionSiteRequestResetAction = () => ({
  type: USER_CORRECTION_SITE_REQUEST_RESET,
})

export function userCorrectionSiteRequestReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case USER_CORRECTION_SITE_REQUEST: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case USER_CORRECTION_SITE_REQUEST_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case USER_CORRECTION_SITE_REQUEST_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case USER_CORRECTION_SITE_REQUEST_FAILURE: {
      return {
        data: action.payload,
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

export const userCorrectionRequestAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload: payload.body,
    meta: {
      actionType: USER_CORRECTION_REQUEST,
      url: `${process.env.apiUrl}v0/search/editPiRequest?${stringify(payload.params)}`,
      method: 'PUT',
      auth: true,
      log: false, // for console
      ...meta,
    },
    showNotification: true, // show notification on success
    message: 'Request has been submitted for approval',
    hideNotification: false, // hide notification on error
    customErrMessage: 'Warning : PI already exists with this name',
  })
}

export const userCorrectionRequestResetAction = () => ({
  type: USER_CORRECTION_REQUEST_RESET,
})

export function userCorrectionRequestReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case USER_CORRECTION_REQUEST: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case USER_CORRECTION_REQUEST_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case USER_CORRECTION_REQUEST_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case USER_CORRECTION_REQUEST_FAILURE: {
      return {
        data: action.payload,
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

export const adminApprovalPiAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload: payload.body,
    meta: {
      actionType: ADMIN_APPROVAL,
      url: `${process.env.apiUrl}v0/search/adminApprovalAdd?${stringify(payload.params)}`,
      method: 'PUT',
      auth: true,
      log: false, // for console
      ...meta,
    },
    showNotification: true, // show notification on success
    message: meta.status_flag === 'Approved' ? 'Investigator sucessfully added to Landscape' : meta.status_flag === 'Rejected' ? 'Investigator has been rejected' : 'Investigator has been sent for clarification',
    hideNotification: false, // hide notification on error
  })
}

export const adminApprovalPiResetAction = () => ({
  type: ADMIN_APPROVAL_RESET,
})

export function adminApprovalPiReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case ADMIN_APPROVAL: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case ADMIN_APPROVAL_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case ADMIN_APPROVAL_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case ADMIN_APPROVAL_FAILURE: {
      return {
        data: action.payload,
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

export const adminApprovalEditPiAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload: payload.body,
    meta: {
      actionType: ADMIN_APPROVAL_EDIT,
      url: `${process.env.apiUrl}v0/search/adminApprovalEdit?${stringify(payload.params)}`,
      method: 'PUT',
      auth: true,
      log: false, // for console
      ...meta,
    },
    showNotification: true, // show notification on success
    message: meta.status_flag === 'Approved' ? 'Investigator sucessfully added to Landscape' : meta.status_flag === 'Rejected' ? 'Investigator has been rejected' : 'Investigator has been sent for clarification',
    hideNotification: false, // hide notification on error
  })
}

export const adminApprovalEditPiResetAction = () => ({
  type: ADMIN_APPROVAL_EDIT_RESET,
})

export function adminApprovalEditPiReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case ADMIN_APPROVAL_EDIT: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case ADMIN_APPROVAL_EDIT_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case ADMIN_APPROVAL_EDIT_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case ADMIN_APPROVAL_EDIT_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

const PI_FILTER = 'PI_FILTER'
const PI_FILTER_SUCCESS = 'PI_FILTER_SUCCESS'
const PI_FILTER_RESET = 'PI_FILTER_RESET'
const PI_FILTER_FAILURE = 'PI_FILTER_FAILURE'

export const getPiFilterDataAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload,
    meta: {
      actionType: PI_FILTER,
      url: `${process.env.apiUrl}v0/search/getPiFilters`,
      method: 'GET',
      auth: true,
      log: false,
      ...meta,
    },
    showNotification: false,
    hideNotification: false,
  })
}

export const getPiFilterDataResetAction = () => ({
  type: PI_FILTER_RESET,
})

export function getPiFilterDataReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case PI_FILTER: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case PI_FILTER_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }

    case PI_FILTER_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }

    case PI_FILTER_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}
