import { stringify } from 'query-string'
import { GENERIC_ACTION } from '../../container/SearchResults/logic'

const CREATE_PI = 'CREATE_PI'
const CREATE_PI_SUCCESS = 'CREATE_PI_SUCCESS'
const CREATE_PI_RESET = 'CREATE_PI_RESET'
const CREATE_PI_FAILURE = 'CREATE_PI_FAILURE'

const INITIAL_STATE = {
  data: {},
  loading: false,
  error: false,
  flag: false,
}

export const createPiAction = (payload, meta = {}) => {
  console.log('payload.body', payload.body)
  return ({
    type: GENERIC_ACTION,
    payload: payload.body,
    meta: {
      actionType: CREATE_PI,
      url: `${process.env.apiUrl}v0/search/createpi?${stringify(payload.params)}`,
      method: 'POST',
      auth: true,
      log: false,
      ...meta,
    },
    showNotification: true,
    message: payload.body?.file_name ? 'Data saved successfully' : 'Investigator sent for approval',
    hideNotification: false,
    customErrMessage: 'Warning : PI already exists with this name',
  })
}

export const addPiResetAction = () => ({
  type: CREATE_PI_RESET,
})

export function createPiReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case CREATE_PI: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case CREATE_PI_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case CREATE_PI_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case CREATE_PI_FAILURE: {
      return {
        data: action.payload,
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

const UPDATE_PI = 'UPDATE_PI'
const UPDATE_PI_SUCCESS = 'UPDATE_PI_SUCCESS'
const UPDATE_PI_RESET = 'UPDATE_PI_RESET'
const UPDATE_PI_FAILURE = 'UPDATE_PI_FAILURE'

export const updatePiAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload: payload.body,
    meta: {
      actionType: UPDATE_PI,
      url: `${process.env.apiUrl}v0/search/updatepi?${stringify(payload.params)}`,
      method: 'PUT',
      auth: true,
      log: false,
      ...meta,
    },
    showNotification: true,
    message: payload.params.direct_edit ? 'Investigator updated successfully' : 'Investigator sent for approval',
    hideNotification: false,
    customErrMessage: 'Warning : PI already exists with this name',
  })
}

export const updatePiResetAction = () => ({
  type: UPDATE_PI_RESET,
})

export function updatePiReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case UPDATE_PI: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case UPDATE_PI_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case UPDATE_PI_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case UPDATE_PI_FAILURE: {
      return {
        data: action.payload,
        loading: false,
        flag: false,
        error: true,
        status: action.payload.status,
      }
    }
    default:
      return state
  }
}

const PI_FILTER = 'PI_FILTER'
const PI_FILTER_SUCCESS = 'PI_FILTER_SUCCESS'
const PI_FILTER_RESET = 'PI_FILTER_RESET'
const PI_FILTER_FAILURE = 'PI_FILTER_FAILURE'

export const getPiFilterDataAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload,
    meta: {
      actionType: PI_FILTER,
      url: `${process.env.apiUrl}v0/search/getPiFilters`,
      method: 'GET',
      auth: true,
      log: false,
      ...meta,
    },
    showNotification: false,
    hideNotification: false,
  })
}

export const getPiFilterDataResetAction = () => ({
  type: PI_FILTER_RESET,
})

export function getPiFilterDataReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case PI_FILTER: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case PI_FILTER_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }

    case PI_FILTER_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }

    case PI_FILTER_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

const SIMS_ID_PRESENT = 'SIMS_ID_PRESENT'
const SIMS_ID_PRESENT_SUCCESS = 'SIMS_ID_PRESENT_SUCCESS'
const SIMS_ID_PRESENT_RESET = 'SIMS_ID_PRESENT_RESET'
const SIMS_ID_PRESENT_FAILURE = 'SIMS_ID_PRESENT_FAILURE'

export const simsIdPresentAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload,
    meta: {
      actionType: SIMS_ID_PRESENT,
      url: `${process.env.apiUrl}v0/search/isSimsIdAssigned`,
      method: 'GET',
      auth: true,
      log: false,
      ...meta,
    },
  })
}

export const simsIdPresentResetAction = () => ({
  type: SIMS_ID_PRESENT_RESET,
})

export function simsIdPresentReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case SIMS_ID_PRESENT: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case SIMS_ID_PRESENT_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case SIMS_ID_PRESENT_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case SIMS_ID_PRESENT_FAILURE: {
      return {
        data: action.payload,
        loading: false,
        flag: false,
        error: true,
        status: action.payload.status,
      }
    }
    default:
      return state
  }
}

const GET_PARENT_DETAILS = 'GET_PARENT_DETAILS'
const GET_PARENT_DETAILS_SUCCESS = 'GET_PARENT_DETAILS_SUCCESS'
const GET_PARENT_DETAILS_RESET = 'GET_PARENT_DETAILS_RESET'
const GET_PARENT_DETAILS_FAILURE = 'GET_PARENT_DETAILS_FAILURE'

export const getParentDetailsAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload,
    meta: {
      actionType: GET_PARENT_DETAILS,
      url: `${process.env.apiUrl}v0/institution/parentDetailsOfCenter`,
      method: 'GET',
      auth: true,
      log: false,
      ...meta,
    },
  })
}

export const getParentDetailsResetAction = () => ({
  type: GET_PARENT_DETAILS_RESET,
})

export function getParentDetailsReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case GET_PARENT_DETAILS: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case GET_PARENT_DETAILS_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case GET_PARENT_DETAILS_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case GET_PARENT_DETAILS_FAILURE: {
      return {
        data: action.payload,
        loading: false,
        flag: false,
        error: true,
        status: action.payload.status,
      }
    }
    default:
      return state
  }
}
